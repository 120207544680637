'use strict';

Gri.module({
  name: 'carousel-beta',
  ieVersion: null,
  $el: $('.carousel-beta'),
  container: '.carousel-beta',
  fn: function () {

    // region variables
    const $this = this.$el;
    // endregion

    // region run
    $this.owlCarousel({
      margin: 15,
      dots: true,
      responsiveClass: true,
      lazyLoad: true,
      navText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 2
        },
        992: {
          items: 4
        },
        1100: {
          items: 4,
          nav: true
        }
      }
    });
    // endregion

    // region events

    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

  }
});
